<template>
  <v-card
    color="#f8ce79"
    elevation="8"
    class="card_home rounded-xl"
    :style="active ? 'border-style:solid' : ''"
    @mouseover="active = true"
    @mouseleave="active = false"
  >
    <v-card-title>
      <label class="item_title">{{ $t(title) }}</label>
      <v-avatar color="#ea1818" size="35" v-if="count" class="ml-3">
        <span class="white--text font-16">{{ count }}</span>
      </v-avatar>
    </v-card-title>
  </v-card>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "Volunteer",
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    goToProfile() {},
  },
};
</script>
<style scoped>
.card_home {
  min-height: 100px;
  height: 100%;
  background-image: linear-gradient(#efecf4, #f6f4f9, #fbfafc);
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.item_title {
  color: #472583;
  font-family: "Poppins-Medium";
  font-size: 20px;
  margin-left: 5px;
}
.item_count {
  color: white;
  background-color: #ea1818;
  font-family: "Poppins-Medium";
  font-size: 20px;
}
</style>
