var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"rounded-0"},[_c('v-navigation-drawer',{attrs:{"mini-variant":_vm.mini,"permanent":"","width":"300","color":"#472583"},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-row',{style:(_vm.mini
          ? 'justify-content:center; margin-left: -13px;margin-top:0px;'
          : 'margin:0px; justify-content:flex-end')},[_c('v-btn',{staticStyle:{"margin":"10px"},attrs:{"color":"white","icon":""},on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}},[_c('v-icon',[_vm._v(" mdi-menu ")])],1)],1),_c('v-row',{staticClass:"justify-center"},[_c('v-avatar',{staticClass:"elevation-6 profile",staticStyle:{"margin-top":"50%"},attrs:{"size":_vm.mini ? 40 : 130}},[_c('v-img',{attrs:{"src":_vm.profile.midThumbnail
              ? _vm.profile.midThumbnail
              : _vm.profile.image
              ? _vm.profile.image
              : _vm.placeholderImage,"alt":"Avatar"}})],1)],1),(!_vm.mini)?_c('v-row',{staticClass:"justify-center"},[_c('label',{staticClass:"name_label"},[_vm._v(_vm._s(_vm.profile.first_name + " " + _vm.profile.last_name))])]):_vm._e(),(!_vm.mini)?_c('v-row',[_c('label',{staticClass:"menu_description_title"},[_vm._v("Hey, Admin!")])]):_vm._e(),(!_vm.mini)?_c('v-row',[_c('label',{staticClass:"menu_description_text"},[_vm._v("Here's your manager snapshot")])]):_vm._e(),(!_vm.mini)?_c('v-row',[_c('label',{staticClass:"menu_description_text"},[_vm._v("for "+_vm._s(_vm.timestamp))])]):_vm._e(),(!_vm.mini)?_c('v-row',{staticClass:"justify-center mt-15"},[_c('v-btn',{staticClass:"px-15",attrs:{"rounded":"","color":"#fdbc3a","loading":_vm.loading},on:{"click":_vm.goToProfile}},[_vm._v(" My profile ")])],1):_vm._e(),_c('v-btn',{staticStyle:{"margin":"10px","position":"absolute","bottom":"0"},attrs:{"color":"white","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.onCloseTokenDialog.apply(null, arguments)}}},[_c('v-icon',[_vm._v(" mdi-logout ")])],1)],1),_c('app-dialog',{attrs:{"dialog":_vm.tokenDialog,"onCloseDialog":_vm.onCloseTokenDialog}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }