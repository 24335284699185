import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import HighchartsVue from "highcharts-vue";
import VueDragScroll from "vue-dragscroll";
import * as VueGoogleMaps from "vue2-google-maps";
import VueObserveVisibility from "vue-observe-visibility";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
import VEmojiPicker from "v-emoji-picker";
import linkify from "vue-linkify";
import Vue2Editor from "vue2-editor";
import VueChatScroll from "vue-chat-scroll";
import Notification from "vue-notification";
import VueNativeNotification from "vue-native-notification";

Vue.config.productionTip = false;
Vue.use(HighchartsVue);
Vue.use(VueNativeNotification, {
  requestOnNotify: true,
});
Vue.use(VueDragScroll);
Vue.use(VueObserveVisibility);
Vue.use(VuePlyr);
Vue.use(VEmojiPicker);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAxnwgHYbXKBSCSFe_sFWfIY8F-LPcSJgQ",
    libraries: "places",
  },
  autobindAllEvents: true,
  installComponents: true,
});
Vue.use(Vue2Editor);
Vue.use(VueChatScroll);
Vue.directive("linkified", linkify);
Vue.use(Notification);

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
