import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    interests: [],
    causes: [],
    interestNames: [],
    causeNames: [],
  },
  getters: {
    causes(state) {
      return state.causes;
    },
    interests(state) {
      return state.interests;
    },
    causeNames(state) {
      return state.causeNames;
    },
    interestNames(state) {
      return state.interestNames;
    },
  },
  mutations: {
    setCauseIcons(state, data) {
      state.causes = data;
      state.causeNames = state.causes.map((c) => c.name);
    },
    setInterestIcons(state, data) {
      state.interests = data;
      state.interestNames = state.interests.map((c) => c.name);
    },
    updateIcon(state, data) {
      if (data.type == "Cause") {
        const item = state.causes.find((item) => item._id === data._id);
        if (item) {
          Object.assign(item, data);
        } else {
          state.causes.push(data);
        }
        state.causeNames = state.causes.map((c) => c.name);
      } else if (data.type == "Interest") {
        const item = state.interests.find((item) => item._id === data._id);
        if (item) {
          Object.assign(item, data);
        } else {
          state.interests.push(data);
        }
        state.interestNames = state.interests.map((c) => c.name);
      }
    },
    deleteIcon(state, data) {
      if (data.type == "Cause") {
        state.causes = state.causes.filter((q) => q._id !== data._id);
        state.causeNames = state.causes.map((c) => c.name);
      } else if (data.type == "Interest") {
        state.interests = state.interests.filter((q) => q._id !== data._id);
        state.interestNames = state.interests.map((c) => c.name);
      }
    },
  },
  actions: {
    getCauseIcons({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("icons/causes", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("setCauseIcons", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getInterestIcons({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("icons/interests", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("setInterestIcons", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getIcon({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("icons/get", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addIcon({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("icons/add", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("updateIcon", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateIcon({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("icons/update", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("updateIcon", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteIcon({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("icons/delete", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteIcon", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
