<template>
  <v-card color="#f8ce79" elevation="8" class="card_home rounded-xl">
    <v-card-title
      style="display:flex; flex-direction:row; justify-content:space-between"
    >
      <div style="margin:20px;">
        <v-row class="mb-5">
          <label class="item_title">Manage</label>
        </v-row>
        <v-row class="mt-5 d-none">
          <v-select
            v-model="selected"
            :items="selectOptions"
            filled
            rounded
            dense
            color="#472583"
            style="width: 400px"
          >
          </v-select>
        </v-row>
        <v-row v-for="(item, index) in menu" :key="index" class="mb-3">
          <v-btn rounded dense block @click="goToDetails(item.route)">
            <span style="color: #472583"
              >{{ item.title }}{{ item.count ? ": " + item.count : "" }}</span
            >
            <span style="margin-left: auto; color: #472583;" class="pl-5"
              >{{ item.reply }}
              <v-icon color="#472583">mdi-arrow-right</v-icon></span
            >
          </v-btn>
        </v-row>
      </div>
      <highcharts
        :options="chartOptions"
        class="homechart_container"
        style="width: calc(100% - 400px)"
      ></highcharts>
    </v-card-title>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  props: {
    blogs: Number,
    reported: Number,
  },
  data() {
    return {
      selected: "Volunteer Hours",
      selectOptions: [
        "Volunteer Hours",
        "Donation Income",
        "Products Sold Amount",
        "Ads Income",
      ],
      menu: [
        {
          title: "Send message",
          reply: "Now",
          route: "admin-message",
          count: 0,
        },
        {
          title: "Reported",
          reply: "Check more",
          route: "reported",
          count: 0,
        },
        {
          title: "Blogs",
          reply: "Manage",
          route: "blog-feeds",
          count: 0,
        },
      ],
      chartOptions: {
        showInLegend: false,
        chart: {
          backgroundColor: "white",
          renderTo: "container",
          borderRadius: 20,
          marginBottom: 50,
          marginLeft: 50,
          marginRight: 20,
          marginTop: 50,
          // type: 'column',
        },
        credits: {
          enabled: false,
        },
        title: {
          text: " ", //Distributed Analysis'
        },
        // subtitle: {
        //     text: 'Test options by dragging the sliders below'
        // },
        plotOptions: {
          column: {
            depth: 25,
          },
          series: {
            color: "#7024C4",
          },
        },
        xAxis: {
          labels: {
            formatter: function() {
              let myDate = new Date(this.value);
              return moment(String(myDate)).format("MMM DD");
            },
          },
        },
        yAxis: {
          title: "",
        },
        series: [
          {
            showInLegend: false,
            data: [
              [1356998400000, 10],
              [1359676800000, 22],
              [1362096000000, 30],
              [1364774400000, 56],
              [1367366400000, 85],
              [1370044800000, 101],
              [1372636800000, 152],
              [1375315200000, 254],
              [1377993600000, 321],
              [1380585600000, 760],
              [1383264000000, 870],
              [1385856000000, 1540],
              [1388534400000, 1605],
              [1391212800000, 2201],
              [1393632000000, 2710],
              [1396310400000, 3250],
              [1398902400000, 3650],
              [1401580800000, 4205],
              [1404172800000, 5844],
              [1406851200000, 6460],
            ],
          },
        ],
        tooltip: {
          formatter: function() {
            let myDate = new Date(this.x);
            return moment(String(myDate)).format("MMM DD") + " : " + this.y;
          },
        },
      },
    };
  },
  methods: {
    goToDetails(route) {
      this.$router.push({ name: route });
    },
  },
  watch: {
    blogs(newValue) {
      this.menu[2].count = newValue;
    },
    reported(newValue) {
      this.menu[1].count = newValue;
    },
  },
};
</script>
<style>
.card_home {
  max-height: 410px;
  height: 100%;
  background-image: linear-gradient(#efecf4, #f6f4f9, #fbfafc);
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.highcharts-container {
  height: 100% !important;
}
.highcharts-root {
  height: 100%;
}
.item_title {
  color: #472583;
  font-family: "Poppins-Medium";
  font-size: 20px;
  margin-left: 5px;
}
.homechart_container {
  height: 350px;
  max-height: 350px;
  -webkit-filter: drop-shadow(1px 3px 2px rgba(0, 0, 0, 0.4));
  filter: drop-shadow(1px 3px 2px rgba(0, 0, 0, 0.4));
}
</style>
