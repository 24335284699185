import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    business: [],
  },
  getters: {
    getAllBusiness(state) {
      return state.business;
    },
  },
  mutations: {
    setBusiness(state, data) {
      state.business = data;
    },
    addBusiness(state, data) {
      const item = state.business.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.business.unshift(data);
      }
    },
    editBusiness(state, data) {
      const item = state.business.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.business.push(data);
      }
    },
    deleteBusiness(state, data) {
      state.business = state.business.filter((q) => q._id !== data._id);
    },
  },
  actions: {
    fetchAllBusiness({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("business/all")
          .then((res) => {
            if (res.data.success == true) {
              commit("setBusiness", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getBusiness({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("business/get", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("setBusiness", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addBusiness({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("business/add", params, {
            Authorization: rootState.auth.token,
          })
          .then((res) => {
            if (res.data.success == true) {
              commit("addBusiness", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editBusiness({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("business/update", params, {
            Authorization: rootState.auth.token,
          })
          .then((res) => {
            if (res.data.success == true) {
              commit("editBusiness", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteBusiness({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("business/delete", params, {
            Authorization: rootState.auth.token,
          })
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteBusiness", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getEligibles({ rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("business/eligibles", {
            Authorization: rootState.auth.token,
          })
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getVolunteerHours({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("business/volunteer-hours", param, {
            Authorization: rootState.auth.token,
          })
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
