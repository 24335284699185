<template>
  <v-card :class="{ 'mt-5': top }" @click="onClicked">
    <v-card-title
      class="d-flex flex-row"
      :class="{ 'pt-1 pb-1 pr-2': $vuetify.breakpoint.xs }"
    >
      <div class="ml-2 mr-1">
        <v-img
          :src="icon"
          :width="$vuetify.breakpoint.xs ? 30 : 45"
          :height="$vuetify.breakpoint.xs ? 30 : 45"
          contain
        />
      </div>
      <div class="d-flex flex-column ml-3 align-left">
        <span
          class="app-bold-font"
          :class="$vuetify.breakpoint.xs ? 'font-12' : 'font-14'"
          >{{ title }}</span
        >
        <div class="d-flex align-center mb-1">
          <div>
            <v-img
              :src="require('@/assets/svg/eye.svg')"
              :width="$vuetify.breakpoint.xs ? 15 : 20"
              :height="$vuetify.breakpoint.xs ? 8 : 10"
              contain
            />
          </div>
          <div
            class="gray-font-color ml-2 mt-1 line-height-15"
            :class="$vuetify.breakpoint.xs ? 'font-8' : 'font-12'"
          >
            {{ text }}
          </div>
        </div>
      </div>
      <div class="flex-grow d-flex justify-end">
        <v-icon color="#b5e539" size="20">mdi-arrow-right</v-icon>
      </div>
    </v-card-title>
  </v-card>
</template>
<script>
export default {
  props: {
    onClicked: Function,
    icon: String,
    title: String,
    text: String,
    top: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
