<template>
  <v-card class="rounded-0">
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
      width="300"
      color="#472583"
    >
      <v-row
        :style="
          mini
            ? 'justify-content:center; margin-left: -13px;margin-top:0px;'
            : 'margin:0px; justify-content:flex-end'
        "
      >
        <v-btn
          color="white"
          icon
          @click.stop="mini = !mini"
          style="margin: 10px;"
        >
          <v-icon>
            mdi-menu
          </v-icon>
        </v-btn>
      </v-row>
      <v-row class="justify-center">
        <v-avatar
          class="elevation-6 profile"
          style="margin-top: 50%"
          :size="mini ? 40 : 130"
        >
          <v-img
            :src="
              profile.midThumbnail
                ? profile.midThumbnail
                : profile.image
                ? profile.image
                : placeholderImage
            "
            alt="Avatar"
          />
        </v-avatar>
      </v-row>
      <v-row class="justify-center" v-if="!mini"
        ><label class="name_label">{{
          profile.first_name + " " + profile.last_name
        }}</label></v-row
      >
      <v-row v-if="!mini"
        ><label class="menu_description_title">Hey, Admin!</label></v-row
      >
      <v-row v-if="!mini"
        ><label class="menu_description_text"
          >Here's your manager snapshot</label
        ></v-row
      >
      <v-row v-if="!mini"
        ><label class="menu_description_text">for {{ timestamp }}</label></v-row
      >
      <v-row class="justify-center mt-15" v-if="!mini">
        <v-btn
          rounded
          color="#fdbc3a"
          @click="goToProfile"
          class="px-15"
          :loading="loading"
        >
          My profile
        </v-btn>
      </v-row>
      <v-btn
        color="white"
        icon
        @click.stop="onCloseTokenDialog"
        style="margin: 10px; position: absolute; bottom: 0;"
      >
        <v-icon>
          mdi-logout
        </v-icon>
      </v-btn>
    </v-navigation-drawer>
    <app-dialog
      v-bind:dialog="tokenDialog"
      v-bind:onCloseDialog="onCloseTokenDialog"
    />
  </v-card>
</template>
<script>
import { mapActions, mapState } from "vuex";
import AppDialog from "../AppDialog.vue";
import moment from "moment";
export default {
  components: { AppDialog },
  data() {
    return {
      drawer: true,
      mini: false,
      isSelecting: false,
      loading: false,
      placeholderImage: require("@/assets/default.jpg"),
      tokenDialog: false,
    };
  },
  methods: {
    ...mapActions("auth", {
      onLogout: "onLogout",
      fetchProfile: "fetchProfile",
    }),
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    goToProfile() {
      this.$router.push({ name: "admin-profile" });
    },
    onCloseTokenDialog() {
      this.tokenDialog = false;
      this.onLogout();
      this.$router.push("login");
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
    timestamp: function() {
      return moment(new Date()).format("Do MMM, YYYY");
    },
  },
  created() {
    this.loading = true;
    this.fetchProfile()
      .then(() => (this.loading = false))
      .catch((error) => {
        this.loading = false;
        if (error.response != undefined && error.response.status === 401) {
          this.tokenDialog = true;
        } else {
          console.log(error);
        }
      });
  },
};
</script>
<style scoped>
.edit_button {
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.profile {
  overflow: visible;
}
.name_label {
  color: white;
  font-family: "Poppins-Bold";
  font-size: 25px;
  margin-top: 25px;
}
.menu_description_title {
  color: white;
  font-family: "Poppins-Medium";
  font-size: 20px;
  margin-top: 25px;
  margin-left: 40px;
}
.menu_description_text {
  color: white;
  font-family: "Poppins-Regular";
  font-size: 15px;
  margin-top: 10px;
  margin-left: 40px;
}
.menu_card {
  border-radius: 0px;
}
</style>
