import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    ads: [],
    uploadProgress: 0,
  },
  getters: {
    getAllAds(state) {
      return state.ads;
    },
    getUploadProgress(state) {
      return state.uploadProgress;
    },
  },
  mutations: {
    setAds(state, data) {
      state.ads = data;
    },
    addAds(state, data) {
      const item = state.ads.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.ads.unshift(data);
      }
    },
    editAds(state, data) {
      const item = state.ads.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.ads.push(data);
      }
    },
    deleteAds(state, data) {
      state.ads = state.ads.filter((q) => q._id !== data._id);
    },
    setUploadProgress(state, data) {
      state.uploadProgress = data;
    },
  },
  actions: {
    fetchAllAds({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("trailer/all")
          .then((res) => {
            if (res.data.success == true) {
              commit("setAds", res.data.res);
              resolve();
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getAds({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("trailer/random", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    addAds({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("trailer/add", params, {
            Authorization: rootState.auth.token,
            onUploadProgress: (progress) => {
              commit(
                "setUploadProgress",
                (progress.loaded / progress.total) * 100
              );
            },
          })
          .then((res) => {
            if (res.data.success == true) {
              commit("addAds", {
                ...res.data.res,
                business_name: params.get("business_name"),
              });
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    editAds({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("trailer/update", params, {
            Authorization: rootState.auth.token,
          })
          .then((res) => {
            if (res.data.success == true) {
              commit("editAds", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteAds({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("trailer/delete", params, {
            Authorization: rootState.auth.token,
          })
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteAds", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
