<template>
  <div>
    <div style="position: relative">
      <img
        :src="
          require(`@/assets/svg/navigation/${icon}_${
            selected ? 'selected' : 'unselected'
          }.svg`)
        "
        class="icon-size"
      />
      <div v-if="icon == 'chat' && getUnreadMessage" class="new-message-count">
        {{ getUnreadMessage > 10 ? "•" : getUnreadMessage }}
      </div>
      <div
        v-else-if="icon == 'notifications' && unreadNotification"
        class="new-message-count"
      >
        {{ unreadNotification > 10 ? "•" : unreadNotification }}
      </div>
      <!-- <div class="white-font-color font-10">{{ title }}</div> -->
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    icon: String,
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconTitles: [
        {
          key: "home",
          title: "Home",
        },
        { key: "needs", title: "Needs" },
        { key: "shops", title: "Shop" },
        { key: "chat", title: "Message" },
        { key: "notifications", title: "Notifications" },
      ],
    };
  },
  methods: {
    ...mapActions("notification", {
      getUnreadCount: "getUnreadCount",
    }),
  },
  computed: {
    ...mapGetters({
      getUnreadMessage: "chat/getUnreadMessagesCount",
      unreadNotification: "notification/getUnreadCount",
    }),
    title() {
      var item = {};
      this.iconTitles.map((t) => {
        if (t.key.includes(this.icon)) {
          item = t;
        }
      });
      return item.title;
    },
  },
  watch: {
    "$route.name": function() {
      if (this.icon == "notifications") {
        this.getUnreadCount().catch((error) => {
          console.log(error);
        });
      }
    },
  },
  mounted() {
    if (this.icon == "notifications") {
      this.getUnreadCount()
        .then((res) => {
          this.notificationCount = res;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
};
</script>
<style>
.icon-size {
  width: 24px;
  height: 24px;
}
.new-message-count {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  background: red;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  font-size: 10px;
}
</style>
