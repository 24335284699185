import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    region: [
      localStorage.getItem("user-lanaguage") || "English",
      localStorage.getItem("user-timezone") || "America/New_York",
      localStorage.getItem("user-unit") || "Imperial",
    ],
  },
  getters: {
    getUnit(state) {
      return state.region[2].toLowerCase();
    },
    getTimeZone(state) {
      return state.region[1];
    },
    getLanguage(state) {
      if (state.region[0] == "English") return "en";
      if (state.region[0] == "Spanish") return "es";
      if (state.region[0] == "Portuguese") return "pt";

      return "en";
    },
  },
  mutations: {
    setRegion(state, data) {
      state.region = data;
      localStorage.setItem("user-lanaguage", data[0]);
      localStorage.setItem("user-timezone", data[1]);
      localStorage.setItem("user-unit", data[2]);
    },
    setLanguage(state, data) {
      state.region = [data, state.region[1], state.region[2]];
      state.region = [...state.region];
      localStorage.setItem("user-lanaguage", data[0]);
    },
    setTimezone(state, data) {
      state.region = [state.region[0], data, state.region[2]];
      localStorage.setItem("user-timezone", data[1]);
    },
    setUnit(state, data) {
      state.region = [state.region[0], state.region[1], data];
      localStorage.setItem("user-unit", data[2]);
    },
  },
  actions: {
    getNotificationSettings({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("usersettings/notifications", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setNotificationSetting({ rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("usersettings/set-notification", param)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getRegionSettings({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("usersettings/regions", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("setRegion", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    setRegionSetting({ commit, state, rootState }, param) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("usersettings/set-region", param)
          .then((res) => {
            if (res.data.success == true) {
              if (param.key == "language") {
                commit("setLanguage", param.value);
              } else if (param.key == "timezone") {
                commit("setTimezone", param.value);
              } else if (
                param.key == "unit" &&
                state.region[0] != param.value
              ) {
                commit("setUnit", param.value);
                commit("auth/setWeather", null, { root: true });
              }
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    changePassword({ rootState }, param) {
      if (rootState.auth.type == "Admin") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("admin/change-password", param)
            .then((res) => {
              if (res.data.success == true) {
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      } else if (rootState.auth.type == "Agency") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("agency/change-password", param)
            .then((res) => {
              if (res.data.success == true) {
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      } else if (rootState.auth.type == "Volunteer") {
        return new Promise((resolve, reject) => {
          axiosInstance(rootState.auth.token)
            .post("users/change-password", param)
            .then((res) => {
              if (res.data.success == true) {
                resolve(res.data.res);
              } else {
                reject(res.data.message);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    },
    getTimeZoneName({ state }) {
      const date = new Date();
      console.log(state.region[1]);
      return date
        .toLocaleString("en-US", {
          timeZone: state.region[1],
          timeZoneName: "short",
          day: "2-digit",
        })
        .substring(4);
    },
    convertTZ({ state }, param) {
      if (!param) return "";
      return new Date(
        (typeof param === "string" ? new Date(param) : param).toLocaleString(
          "en-US",
          {
            timeZone: state.region[1],
          }
        )
      );
    },
  },
};
