import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const locale = "en"; // default locale

export default new VueI18n({
  locale,
  fallbackLocale: ["es", "pt"],
  messages: {
    en: {
      needs: "Needs",
      language_settings_title: "Language and region Settings",
      reactions: "Reactions",
      all: "All",
      map: "Map",
      calendar: "Calendar",
      agency: "Agency",
      volunteer: "Volunteer",
      volunteers: "Volunteers",
      awards: "Awards",
      contact: "Contact",
      weather: "Weather",
      donate: "Donate Now",
      events: "Events",
      blogs: "Blogs",
      follow_agencies: "Follow Agencies",
      home: "Home",
      message: "Message",
      notifications: "Notifications",
      account_settings: "Account Settings",
      account_information: "Account Information",
      delete_account: "Delete Account",
      no_search_result: "No results were found for your search",
      follower: "follower",
      followers: "followers",
    },
    es: {
      needs: "Necesidades",
      language_settings_title: "Configuración de idioma y región",
      reactions: "Reacciones",
      all: "Todos",
      map: "Mapa",
      calendar: "Calendario",
      agency: "Agencia",
      volunteer: "Voluntaria",
      volunteers: "Voluntarias",
      events: "Eventos",
      blogs: "Blogs",
      awards: "Premios",
      contact: "Contacto",
      weather: "Tiempo",
      donate: "Done ahora",
      follow_agencies: "Seguir agencias",
      home: "Pantalla",
      message: "Mensaje",
      notifications: "Notificaciones",
      Account: "Cuenta",
      Notifications: "Notificaciones",
      Password: "Clave",
      "Language and region": "Idioma y región",
      Blocked: "Obstruida",
      account_settings: "Configuraciones de la cuenta",
      account_information: "Información de la cuenta",
      delete_account: "Borrar cuenta",
      no_search_result: "No se encontraron resultados para tu búsqueda",
      follower: "seguidor",
      followers: "seguidores",
    },
    pt: {
      needs: "Precisa",
      language_settings_title: "Configurações de idioma e região",
      reactions: "Reações",
      all: "Toda",
      map: "Mapa",
      calendar: "Calendário",
      agency: "Agência",
      volunteer: "Voluntária",
      volunteers: "Voluntárias",
      awards: "Prêmios",
      events: "Eventos",
      blogs: "Blogues",
      contact: "Contato",
      weather: "Tempo",
      donate: "DOE agora",
      follow_agencies: "Siga agências",
      home: "Tela",
      message: "Mensagem",
      notifications: "Notificações",
      Account: "Conta",
      Notifications: "Notificações",
      Password: "Senha",
      "Language and region": "Idioma e região",
      Blocked: "Bloqueado",
      account_settings: "Configurações da conta",
      account_information: "Informação da conta",
      delete_account: "Deletar conta",
      no_search_result: "Não foram encontrados resultados para a sua pesquisa",
      follower: "seguidor",
      followers: "seguidores",
    },
  },
});
