<template>
  <v-app class="app-bg">
    <navigation v-if="showMenu" />
    <v-main
      class="content-view"
      :style="
        showMenu && $vuetify.breakpoint.xs && $route.name == 'need-map'
          ? 'padding-top:56px;'
          : showMenu &&
            $vuetify.breakpoint.xs &&
            $route.name != 'mobile-chat-room'
          ? 'padding-top:70px; padding-bottom: 56px'
          : showMenu
          ? 'padding-top:70px;'
          : ''
      "
    >
      <router-view />
    </v-main>
    <notifications group="message" position="bottom right" />
    <bottom-navigation
      v-if="showMenu && $route.name != 'mobile-chat-room'"
      class="hidden-sm-and-up"
    />
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import BottomNavigation from "./components/BottomNavigation.vue";

export default {
  name: "App",
  components: {
    Navigation,
    BottomNavigation,
  },
  data() {
    return {
      showMenu: false,
    };
  },
  watch: {
    $route(to) {
      if (
        to.name === "login" ||
        to.name === "admin-home" ||
        to.name == "agency-register" ||
        to.name == "user-register" ||
        to.name == "forgot-password" ||
        to.name == "verification-code" ||
        to.name == "reset-password"
      ) {
        this.showMenu = false;
      } else {
        this.showMenu = true;
      }
    },
  },
};
</script>
<style lang="scss">
@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
    url(./assets/fonts/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"),
    url(./assets/fonts/Poppins-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
    url(./assets/fonts/Poppins-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"),
    url(./assets/fonts/Poppins-SemiBold.ttf) format("truetype");
}
html {
  height: 100%;
}
html #detach-button-host {
  z-index: 10000 !important;
}
.app-bg {
  background: #e5e5e5 !important;
  background-size: cover !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.content-view {
  // height: 100%;
  // max-height: 100%;
  min-height: calc(100vh - 70px);
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.v-main__wrap {
  min-height: inherit;
}
.url-button {
  cursor: pointer;
}
.url-button:hover {
  text-decoration: underline;
  color: #0a66c2;
}
.cursor-pointer {
  cursor: pointer;
}

///Text
.text-block {
  white-space: pre-line;
  overflow: hidden;
  display: block;
}
.text-ellipsis {
  text-overflow: ellipsis;
}
.break-word {
  word-break: break-word;
}
.text-transform-none {
  text-transform: none;
}
.line-height-15 {
  line-height: 15px !important;
}
.text-line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

///Colors
.primary-color {
  color: #6038f7 !important;
}
.dark-font-color {
  color: #1e1e1e !important;
}
.dark-gray-color {
  color: #242f36;
}
.white-font-color {
  color: white;
}
.blue-font-color {
  color: #5b2cae;
}
.gray-font-color {
  color: #919191;
}
.disabled-font-color {
  color: lightgray;
}
.selected-item-color {
  background-color: #55eef3f8;
}
.hover-item-color {
  color: #55eef3f8;
}
.bg-white {
  background-color: white;
}

.app-bold-font {
  font-family: "Poppins-SemiBold" !important;
}
.app-medium-font {
  font-family: "Poppins-Medium" !important;
}
.app-regular-font {
  font-family: "Poppins-Regular" !important;
}
.font-normal {
  font-weight: normal;
}

.w-100 {
  width: 100% !important;
}
.h-100 {
  height: 100% !important;
}
.min-width-200 {
  min-width: 200px;
}
.max-width-650 {
  max-width: 650px;
}
.max-width-1500 {
  max-width: 1500px;
}
.max-width-2000 {
  max-width: 2000px;
}
.force-height-30 {
  max-height: 30px !important;
  min-height: 30px !important;
}
.force-width-400 {
  max-width: 400px !important;
  min-width: 400px !important;
}
.max-height-0 {
  max-height: 0;
}
.min-height-inherit {
  min-height: inherit;
}
.top-70 {
  top: 70px;
}
.b-0 {
  bottom: 0;
}
.r-0 {
  right: 0;
}

.font-8 {
  font-size: 8px !important;
}
.font-10 {
  font-size: 10px !important;
}
.font-12 {
  font-size: 12px !important;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px !important;
}
.font-24 {
  font-size: 24px !important;
}
.font-bold {
  font-weight: bold;
}
.position-sticky {
  position: sticky;
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.justify-between {
  justify-content: space-between !important;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-grow {
  flex-grow: 1;
}
.flex-shrink {
  flex-shrink: 0;
}
.hover-item:hover {
  background-color: #eef3f8;
}
.hover-scroll:hover {
  overflow-y: auto !important;
}
.hover-scroll::-webkit-scrollbar {
  width: 8px;
  transition-duration: 0.5s;
}

/* Track */
.hover-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.hover-scroll::-webkit-scrollbar-thumb {
  background: rgba(240, 160, 0, 0.3);
  border-radius: 3px;
}

/* Handle on hover */
.hover-scroll::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}

#gl-widget-modal {
  display: none;
}

@media (max-width: 600px) {
  .vue-notification-group {
    bottom: 56px !important;
  }
}
</style>
