var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticClass:"app-bg"},[(_vm.showMenu)?_c('navigation'):_vm._e(),_c('v-main',{staticClass:"content-view",style:(_vm.showMenu && _vm.$vuetify.breakpoint.xs && _vm.$route.name == 'need-map'
        ? 'padding-top:56px;'
        : _vm.showMenu &&
          _vm.$vuetify.breakpoint.xs &&
          _vm.$route.name != 'mobile-chat-room'
        ? 'padding-top:70px; padding-bottom: 56px'
        : _vm.showMenu
        ? 'padding-top:70px;'
        : '')},[_c('router-view')],1),_c('notifications',{attrs:{"group":"message","position":"bottom right"}}),(_vm.showMenu && _vm.$route.name != 'mobile-chat-room')?_c('bottom-navigation',{staticClass:"hidden-sm-and-up"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }