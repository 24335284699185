<template>
  <v-dialog width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-card
        :class="{ 'mt-5': top }"
        @click="showDonation"
        v-bind="attrs"
        v-on="on"
      >
        <v-card-title
          class="d-flex flex-row"
          :class="{ 'pt-1 pb-1 pr-2': $vuetify.breakpoint.xs }"
        >
          <div class="ml-2 mr-1">
            <v-img
              :src="require('@/assets/svg/donations.svg')"
              :width="$vuetify.breakpoint.xs ? 30 : 45"
              :height="$vuetify.breakpoint.xs ? 30 : 45"
              contain
            />
          </div>
          <div class="d-flex flex-column ml-3 align-left justify-center py-3">
            <span
              class="app-bold-font"
              :class="$vuetify.breakpoint.xs ? 'font-12' : 'font-14'"
              >{{ $t("donate") }}</span
            >
          </div>
          <div style="flex-grow:1; display: flex; justify-content: flex-end;">
            <v-icon color="#b5e539" size="20">mdi-arrow-right</v-icon>
          </div>
        </v-card-title>
      </v-card>
    </template>
    <v-card>
      <div
        id="give-lively-widget"
        class="gl-simple-donation-widget"
        v-if="donationDialog"
      />
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    top: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      donationDialog: false,
    };
  },
  methods: {
    showDonation() {
      this.donationDialog = true;
    },
  },
};
</script>
