import axiosInstance from "@/api/axios";

export default {
  namespaced: true,
  state: {
    logs: [],
  },
  getters: {
    getAllLogs(state) {
      return state.logs;
    },
  },
  mutations: {
    setLogs(state, data) {
      state.logs = data;
    },
    addLog(state, data) {
      const item = state.logs.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.logs.unshift(data);
      }
    },
    editLog(state, data) {
      const item = state.logs.find((item) => item._id === data._id);
      if (item) {
        Object.assign(item, data);
      } else {
        state.logs.push(data);
      }
    },
    deleteLog(state, data) {
      state.logs = state.logs.filter((q) => q._id !== data._id);
    },
  },
  actions: {
    fetchAllLogs({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("message-log/all", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("setLogs", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateLog({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("message-log/update", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("editLog", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteLog({ commit, rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("message-log/delete", params)
          .then((res) => {
            if (res.data.success == true) {
              commit("deleteLog", res.data.res);
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getLogDetails({ rootState }, params) {
      return new Promise((resolve, reject) => {
        axiosInstance(rootState.auth.token)
          .post("message-log/get", params)
          .then((res) => {
            if (res.data.success == true) {
              resolve(res.data.res);
            } else {
              reject(res.data.message);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
