import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/admin/AdminHome.vue";
import store from "@/store";

Vue.use(VueRouter);
const DEFAULT_TITLE = "Akindr";
const routes = [
  {
    path: "/",
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/agency/home",
    name: "agency-home",
    component: () =>
      import(
        /* webpackChunkName: "agency-home" */ "../views/agency/AgencyHome.vue"
      ),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/agency/register",
    name: "agency-register",
    component: () =>
      import(
        /* webpackChunkName: "agency-register" */ "../views/getstarted/RegisterAgency.vue"
      ),
    props: true,
    meta: { requiresAuth: false, title: "Agency Register | Akindr" },
  },
  {
    path: "/user/register",
    name: "user-register",
    component: () =>
      import(
        /* webpackChunkName: "volunteer-register" */ "../views/getstarted/RegisterVolunteer.vue"
      ),
    props: true,
    meta: {
      requiresAuth: false,
      title: "Volunteer Register | Akindr",
    },
  },
  {
    path: "/volunteer/home",
    name: "volunteer-home",
    component: () =>
      import(
        /* webpackChunkName: "volunteer-home" */ "../views/volunteer/VolunteerHome.vue"
      ),
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/needs",
    name: "agency-needs",
    component: () =>
      import(
        /* webpackChunkName: "agency-needs" */ "../views/agency/needs/AgencyNeeds.vue"
      ),
    children: [
      {
        path: "feeds",
        name: "agency-need-feeds",
        meta: {
          requiresAuth: true,
          title: "Agencies Needs | AKINDr",
        },
        component: () => import("../views/agency/needs/AgencyNeedFeeds.vue"),
      },
      {
        path: "discover",
        name: "agency-need-discover",
        meta: {
          requiresAuth: true,
          title: "Agencie Needs | Akindr",
        },
        component: () => import("../views/agency/needs/AgencyNeedDiscover.vue"),
      },
      {
        path: "notification",
        name: "agency-need-notification",
        meta: {
          requiresAuth: true,
          title: "Agencie Needs | Akindr",
        },
        component: () =>
          import("../views/agency/needs/AgencyNeedNotification.vue"),
      },
      {
        path: "details",
        name: "agency-need-details",
        component: () =>
          import(
            /* webpackChunkName: "agency-need-details" */ "../views/agency/needs/AgencyNeedDetails.vue"
          ),
        props: true,
        meta: { requiresAuth: true },
      },
    ],
    props: true,
    meta: { requiresAuth: true },
  },

  {
    path: "/agency-shop",
    name: "agency-shop",
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "../views/agency/shop/AgencyShop.vue"
      ),
    children: [
      {
        path: "feeds",
        name: "agency-shop-feeds",
        meta: {
          requiresAuth: true,
          title: "Shop | Akindr",
        },
        component: () => import("../views/agency/shop/AgencyShopFeeds.vue"),
      },
      {
        path: "notification",
        name: "agency-shop-notification",
        meta: {
          requiresAuth: true,
          title: "Notification | Akindr",
        },
        component: () =>
          import("../views/agency/shop/AgencyShopNotification.vue"),
      },
    ],
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/chat",
    name: "chat",
    component: () =>
      import(/* webpackChunkName: "notifications" */ "../views/chat/Chat.vue"),
    children: [
      {
        path: "details",
        name: "chat-details",
        meta: {
          requiresAuth: true,
          title: "Chat | Akindr",
        },
        component: () => import("../views/chat/ChatMessages.vue"),
      },
      {
        path: "empty",
        name: "no-messages",
        meta: {
          requiresAuth: true,
          title: "No messages | Akindr",
        },
        component: () => import("../views/chat/NoMessages.vue"),
      },
    ],
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/mobile-chat",
    name: "mobile-chat",
    meta: { requiresAuth: true, title: "Chat | Akindr" },
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "../views/chat/MobileChat.vue"
      ),
  },
  {
    path: "/mobile-chat-room",
    name: "mobile-chat-room",
    meta: { requiresAuth: true, title: "Chat | Akindr" },
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "../views/chat/MobileChatMessages.vue"
      ),
  },
  {
    path: "/agency-profile",
    name: "agency-profile",
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "../views/agency/profile/AgencyProfile.vue"
      ),
    children: [
      {
        path: ":id",
        name: "agency-profile-details",
        meta: {
          requiresAuth: true,
          title: "Profile | Akindr",
        },
        component: () =>
          import("../views/agency/profile/AgencyProfileDetails.vue"),
        children: [
          {
            path: "events",
            name: "agency-profile-events",
            meta: {
              requiresAuth: true,
              title: "Profile | Akindr",
            },
            component: () =>
              import("../views/agency/profile/AgencyProfileEvents.vue"),
          },
          {
            path: "about",
            name: "agency-profile-about",
            meta: {
              requiresAuth: true,
              title: "Profile | Akindr",
            },
            component: () =>
              import("../views/agency/profile/AgencyProfileAbout.vue"),
          },
          {
            path: "needs",
            name: "agency-profile-needs",
            meta: {
              requiresAuth: true,
              title: "Profile | Akindr",
            },
            component: () =>
              import("../views/agency/profile/AgencyProfileNeeds.vue"),
          },
          {
            path: "volunteers",
            name: "agency-profile-volunteers",
            meta: {
              requiresAuth: true,
              title: "Profile | Akindr",
            },
            component: () =>
              import("../views/agency/profile/AgencyProfileVolunteers.vue"),
          },
        ],
      },
    ],
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/volunteer-profile",
    name: "volunteer-profile",
    component: () =>
      import(
        /* webpackChunkName: "volunteer-profile" */ "../views/volunteer/profile/VolunteerProfile.vue"
      ),
    children: [
      {
        path: ":id",
        name: "volunteer-profile-details",
        meta: {
          requiresAuth: true,
          title: "Profile | Akindr",
        },
        component: () =>
          import("../views/volunteer/profile/VolunteerProfileDetails.vue"),
        children: [
          {
            path: "events",
            name: "volunteer-profile-events",
            meta: {
              requiresAuth: true,
              title: "Profile | Akindr",
            },
            component: () =>
              import("../views/volunteer/profile/VolunteerProfileEvents.vue"),
          },
          {
            path: "about",
            name: "volunteer-profile-about",
            meta: {
              requiresAuth: true,
              title: "Profile | Akindr",
            },
            component: () =>
              import("../views/volunteer/profile/VolunteerProfileAbout.vue"),
          },
          {
            path: "needs",
            name: "volunteer-profile-needs",
            meta: {
              requiresAuth: true,
              title: "Profile | Akindr",
            },
            component: () =>
              import("../views/volunteer/profile/VolunteerProfileNeeds.vue"),
          },
          {
            path: "agencies",
            name: "volunteer-profile-agencies",
            meta: {
              requiresAuth: true,
              title: "Profile | Akindr",
            },
            component: () =>
              import("../views/volunteer/profile/VolunteerProfileAgencies.vue"),
          },
        ],
      },
    ],
    props: true,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin",
    name: "admin-home",
    component: Home,
    props: true,
    meta: {
      requiresAuth: true,
      title: "Admin | Akindr",
      permission: ["Admin"],
    },
  },
  {
    path: "/admin/volunteers",
    name: "admin-volunteers",
    meta: {
      requiresAuth: true,
      title: "Volunteers | Akindr",
      permission: ["Admin"],
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-volunteers" */ "../views/admin/AdminVolunteers.vue"
      ),
    props: true,
  },
  {
    path: "/admin/agencies",
    name: "admin-agencies",
    meta: {
      requiresAuth: true,
      title: "Agencies | Akindr",
      permission: ["Admin"],
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-agencies" */ "../views/admin/AdminAgencies.vue"
      ),
    children: [
      {
        path: ":id",
        name: "agency-details",
        meta: {
          requiresAuth: true,
          title: "Agencie Details | Akindr",
        },
        component: () => import("../views/admin/AdminAgencyDetails.vue"),
      },
    ],
    props: true,
  },
  {
    path: "/admin/events",
    name: "events",
    meta: {
      requiresAuth: true,
      title: "Events | Akindr",
      permission: ["Admin"],
    },
    component: () =>
      import(/* webpackChunkName: "events" */ "../views/admin/AdminEvents.vue"),
    props: true,
  },
  {
    path: "/admin/business",
    name: "admin-business",
    meta: {
      requiresAuth: true,
      title: "Business | Akindr",
      permission: ["Admin"],
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-business" */ "../views/admin/business/AdminBusinessHome.vue"
      ),
    props: true,
  },
  {
    path: "/admin/ads",
    name: "admin-ads",
    meta: { requiresAuth: true, title: "Ads | Akindr", permission: ["Admin"] },
    component: () =>
      import(
        /* webpackChunkName: "admin-ads" */ "../views/admin/ads/AdminAdsHome.vue"
      ),
    props: true,
  },
  {
    path: "/reported",
    name: "reported",
    meta: {
      requiresAuth: true,
      title: "Reported | Admin",
      permission: ["Admin"],
    },
    component: () =>
      import(
        /* webpackChunkName: "reported" */ "../views/admin/AdminReported.vue"
      ),
    props: true,
  },
  {
    path: "/event-details",
    name: "Event details",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "event-details" */ "../views/event_feeds/details/EventDetailsHome.vue"
      ),
    props: true,
  },
  {
    path: "/notifications",
    name: "notifications",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "notifications" */ "../views/notification/NotificationHome.vue"
      ),
    props: true,
  },
  {
    path: "/search",
    name: "search",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "search" */ "../views/search/SearchHome.vue"),
    props: true,
  },
  {
    path: "/settings",
    name: "settings",
    meta: { requiresAuth: true, title: "Settings | Akindr" },
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "../views/settings/SettingHome.vue"
      ),
    props: true,
  },
  {
    path: "/admin-message",
    name: "admin-message",
    meta: {
      requiresAuth: true,
      title: "Message | Akindr",
      permission: ["Admin"],
    },
    component: () =>
      import(
        /* webpackChunkName: "admin-message" */ "../views/admin/message/AdminMessageHome.vue"
      ),
    props: true,
  },
  {
    path: "/agency-message",
    name: "agency-message",
    meta: {
      requiresAuth: true,
      title: "Message | Akindr",
      permission: ["Agency"],
    },
    component: () =>
      import(
        /* webpackChunkName: "agency-message" */ "../views/agency/message/AgencyMessageHome.vue"
      ),
    props: true,
  },
  {
    path: "/need-map",
    name: "need-map",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "need-map" */ "../views/need_map/NeedMapHome.vue"
      ),
    props: true,
  },
  {
    path: "/weather",
    name: "weather",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "weather" */ "../views/weather/WeatherHome.vue"
      ),
    props: true,
  },
  {
    path: "/calendar",
    name: "calendar",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "calendar" */ "../views/calendar/CalendarHome.vue"
      ),
    props: true,
  },
  {
    path: "/awards",
    name: "awards",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "awards" */ "../views/awards/AwardsHome.vue"),
    props: true,
  },
  {
    path: "/volunteer-agencies",
    name: "volunteer-agencies",
    meta: { requiresAuth: true, title: "Agencies | Akindr" },
    component: () =>
      import(
        /* webpackChunkName: "volunteer-agencies" */ "../views/volunteer/agencies/VolunteerAgencies.vue"
      ),
    props: true,
  },
  {
    path: "/volunteer-diary",
    name: "volunteer-diary",
    meta: { requiresAuth: true, title: "Agencies | Akindr" },
    component: () =>
      import(
        /* webpackChunkName: "volunteer-diary" */ "../views/volunteer/response/VolunteerDiary.vue"
      ),
    props: true,
  },
  {
    path: "/agencies",
    name: "agencies",
    meta: { requiresAuth: true, title: "Agencies | Akindr" },
    component: () =>
      import(
        /* webpackChunkName: "agencies" */ "../views/agency/agencies/Agencies.vue"
      ),
    props: true,
  },
  {
    path: "/agency-volunteers",
    name: "agency-volunteers",
    meta: { requiresAuth: true, title: "Volunteers | Akindr" },
    component: () =>
      import(
        /* webpackChunkName: "agency-volunteers" */ "../views/agency/volunteers/AgencyVolunteers.vue"
      ),
    props: true,
  },
  {
    path: "/agency-responses",
    name: "agency-responses",
    meta: { requiresAuth: true, title: "Responses | Akindr" },
    component: () =>
      import(
        /* webpackChunkName: "agency-responses" */ "../views/agency/responses/AgencyResponses.vue"
      ),
    children: [
      {
        path: "feeds",
        name: "agency-responses-feeds",
        meta: {
          requiresAuth: true,
          title: "Responses | Akindr",
        },
        component: () =>
          import("../views/agency/responses/AgencyResponsesSection.vue"),
      },
      {
        path: "diary",
        name: "agency-responses-diary",
        meta: { requiresAuth: true, title: "Work Diary | Akindr" },
        component: () =>
          import(
            /* webpackChunkName: "agency-responses-diary" */ "../views/agency/responses/AgencyVolunteerDiary.vue"
          ),
        props: true,
      },
    ],
    props: true,
  },
  {
    path: "/volunteers",
    name: "volunteers",
    meta: { requiresAuth: true, title: "Volunteers | Akindr" },
    component: () =>
      import(
        /* webpackChunkName: "volunteers" */ "../views/volunteer/volunteers/Volunteers.vue"
      ),
    props: true,
  },
  {
    path: "/volunteer-hours",
    name: "volunteer-hours",
    meta: {
      requiresAuth: true,
      title: "Volunteers | Akindr",
      permission: ["Volunteer"],
    },
    component: () =>
      import(
        /* webpackChunkName: "volunteers" */ "../views/volunteer/work_history/VolunteerHours.vue"
      ),
    props: true,
  },
  {
    path: "/edit-agency/:id",
    name: "edit-agency",
    meta: { requiresAuth: true, title: "Edit Agency | Akindr" },
    component: () =>
      import(
        /* webpackChunkName: "products" */ "../views/admin/AdminEditAgency.vue"
      ),
    props: true,
  },
  {
    path: "/products",
    name: "products",
    meta: { requiresAuth: true, title: "Products | Akindr" },
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/Products.vue"),
    props: true,
  },
  {
    path: "/support-center",
    name: "support-center",
    meta: { requiresAuth: true },
    component: () =>
      import(
        /* webpackChunkName: "support-center" */ "../views/SupportCenter.vue"
      ),
    props: true,
  },
  {
    path: "/donations",
    name: "donations",
    meta: { requiresAuth: true, title: "Donations | Akindr" },
    component: () =>
      import(/* webpackChunkName: "donations" */ "../views/Donations.vue"),
    props: true,
  },
  {
    path: "/blogs",
    name: "blogs",
    meta: { requiresAuth: true },
    component: () =>
      import(/* webpackChunkName: "blogs" */ "../views/blogs/BlogHome.vue"),
    children: [
      {
        path: "feeds",
        name: "blog-feeds",
        meta: {
          requiresAuth: false,
          title: "Blogs | Akindr",
        },
        component: () => import("../views/blogs/BlogFeeds.vue"),
      },
      {
        path: "details",
        name: "blog-details",
        meta: { requiresAuth: true, title: "Blog | Akindr" },
        component: () =>
          import(
            /* webpackChunkName: "blog-details" */ "../views/blogs/BlogDetails.vue"
          ),
        props: true,
      },
      {
        path: "create",
        name: "create-blog",
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "create-blog" */ "../views/blogs/CreateBlog.vue"
          ),
        props: true,
      },
      {
        path: "edit",
        name: "edit-blog",
        meta: { requiresAuth: true },
        component: () =>
          import(
            /* webpackChunkName: "edit-blog" */ "../views/blogs/EditBlog.vue"
          ),
        props: true,
      },
    ],
    props: true,
  },
  {
    path: "/admin-profile",
    name: "admin-profile",
    meta: { requiresAuth: true, title: "Admin Profile | Akindr" },
    component: () =>
      import(
        /* webpackChunkName: "admin-profile" */ "../views/admin/AdminProfile.vue"
      ),
    props: true,
  },
  {
    path: "/login",
    name: "login",
    meta: { requiresAuth: false, title: "Login | Akindr" },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/getstarted/Login.vue"),
    props: true,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    meta: { requiresAuth: false, title: "Forgot Password | Akindr" },
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "../views/getstarted/ForgotPassword.vue"
      ),
    props: true,
  },
  {
    path: "/verification-code",
    name: "verification-code",
    meta: { requiresAuth: false, title: "Verification code | Akindr" },
    component: () =>
      import(
        /* webpackChunkName: "verification-code" */ "../views/getstarted/VerificationCode.vue"
      ),
    props: true,
  },
  {
    path: "/reset-password",
    name: "reset-password",
    meta: { requiresAuth: false, title: "Reset password | Akindr" },
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "../views/getstarted/ResetPassword.vue"
      ),
    props: true,
  },
  {
    path: "/linker",
    name: "linker",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Linker.vue"),
    props: true,
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/NotFound.vue"),
  },
  {
    path: "/*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || DEFAULT_TITLE;
  if (to.meta.requiresAuth) {
    if (store.getters["auth/isAuthenticated"]) {
      if (
        to.meta.permission &&
        !to.meta.permission.includes(store.getters["auth/getType"])
      ) {
        router.push({ name: "home" });
      } else {
        next();
        store.commit("chat/setRoute", to);
      }
    } else {
      next({
        name: "login",
        query: {
          redirect: to.fullPath,
        },
      });
    }
  } else {
    next();
  }
});

export default router;
