<template>
  <div class="bottom-gradient" v-if="type == 'Admin'">
    <v-container fluid style="height: 100%; margin:0px; padding:0px">
      <div style="display:flex; flex-direction:row; height: 100%; margin: 0px">
        <side-menu style="height: 100%" />
        <div
          style="flex-grow:1; padding: 40px 20px 30px 20px; display: flex; flex-direction: column; height: 100%; overflow-y: auto"
        >
          <v-row v-for="(submenu, index) in menu" :key="index">
            <v-col
              v-for="item in submenu.data"
              :key="item.title"
              :cols="item.cols"
            >
              <home-card
                :title="item.title"
                :count="item.count"
                @click.native="goToDetails(item.route)"
              ></home-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <home-charts
                :blogs="homeData.blog"
                :reported="homeData.reported"
              />
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import SideMenu from "@/components/admin/AdminHomeSideMenu";
import HomeCard from "@/components/admin/AdminHomeCard";
import HomeCharts from "@/components/admin/AdminHomeCharts.vue";

export default {
  name: "Home",
  components: {
    SideMenu,
    HomeCard,
    HomeCharts,
  },
  data() {
    return {
      menu: [
        {
          height: 15,
          data: [
            {
              title: "Volunteers",
              cols: "3",
              count: 0,
              route: "admin-volunteers",
            },
            { title: "Agencies", cols: "5", count: 0, route: "admin-agencies" },
            {
              title: "Businesses",
              cols: "4",
              count: 0,
              route: "admin-business",
            },
          ],
        },
        {
          height: 15,
          data: [
            {
              title: "Chat",
              cols: "2",
              count: 0,
              route: "chat",
            },
            { title: "Events", cols: "4", count: 0, route: "events" },
            { title: "Ads", cols: "3", count: 0, route: "admin-ads" },
            { title: "Awards", cols: "3", count: 0, route: "awards" },
          ],
        },
        {
          height: 7,
          data: [
            {
              title: "Notifications",
              cols: "3",
              count: 0,
              route: "notifications",
            },
            { title: "Needs", cols: "4", count: 0, route: "agency-need-feeds" },
            { title: "Locations", cols: "5", count: 0, route: "need-map" },
          ],
        },
      ],
      homeData: {},
    };
  },
  methods: {
    ...mapActions("auth", {
      getHomeInfo: "getHomeInfo",
    }),
    goToDetails(route) {
      if (route) {
        this.$router.push({ name: route });
      }
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      type: "type",
    }),
    ...mapGetters({
      conversations: "chat/getConversations",
    }),
  },
  created() {
    if (this.type != "Admin") {
      this.$router.push({ name: "home" });
    }
    this.getHomeInfo()
      .then((res) => {
        console.log(res);
        this.homeData = res;
        this.menu = this.menu.map((line) => {
          line.data = line.data.map((d) => {
            if (d.title == "Volunteers") d.count = res.volunteers;
            if (d.title == "Needs") d.count = res.need;
            if (d.title == "Locations") d.count = res.location;
            if (d.title == "Chat") d.count = this.conversations.length;
            if (d.title == "Agencies") d.count = res.agencies;
            if (d.title == "Businesses") d.count = res.business;
            if (d.title == "Ads") d.count = res.trailer;
            if (d.title == "Awards") d.count = res.awards;
            if (d.title == "Notifications") d.count = res.notifications;
            if (d.title == "Events") d.count = res.events;
            return d;
          });
          return line;
        });
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style scoped>
.bottom-gradient {
  background-image: linear-gradient(#fff9ee, #fee1a8);
  bottom: 0;
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.home_screen {
  background-color: #fff4de;
  height: 100%;
  padding: 20px;
}
.item_title {
  color: #6e3dc6;
  font-family: "Poppins-Bold";
  font-size: 25px;
}
.count_label {
  color: white;
  font-family: "Poppins-SemiBold";
  font-size: 18px;
}
.users_image_section {
  padding-bottom: 20px;
  padding-top: 30px;
  padding-left: 40px;
  justify-content: center;
  max-width: 200px;
}
</style>
